body #dg_footer_wrapper{
	background:#000;
    padding-top: 15px;
}
body #dg_footer_wrapper_inner{
	text-align:center;
 	width: auto;
}
body #dg_footer_wrapper_inner ul li{
	float:none
}
body #dg_footer_wrapper_inner ul li:after{
	content:"|";
	display:inline
}
body #dg_footer_wrapper_inner .dg_footer_row_0 a,
body #dg_footer_wrapper_inner .dg_footer_row_0 a:hover{
	color:#CC0000;
}
body #dg_footer_wrapper_inner .dg_footer_row_0,
body #dg_footer_wrapper_inner ul li span{
	color:#FFFFFF;
}

body #dg_footer_wrapper_inner,
body #dg_footer_wrapper_inner .dg_footer_row_0 {
	font-family:Arial,sans-serif;
	font-size:12px;
	font-style:normal;
	font-weight:400;
	line-height:20px;
	text-align:center
}

body  #dg_footer_wrapper_inner .dg_footer_item_last {
	float: none;
}

#dg_footer_wrapper_inner ul {
    list-style: none;
    padding: 0;
    margin: 0 auto;
    overflow: hidden;
    display: inline-block;
}

#dg_footer_wrapper_inner .dg_footer_item_last {
    float: right
}

#dg_footer_wrapper {
    background: #1f1c13
}

#dg_footer_wrapper_inner {
    text-align: center;
    width: 1100px
}

#dg_footer_wrapper_inner ul li {
    float: none
}

#dg_footer_wrapper_inner ul li:after {
    content: "|";
    display: inline
}

#dg_footer_wrapper_inner .dg_footer_aside_left {
    text-align: center
}

#dg_footer_wrapper_inner .dg_footer_row_0 {
    font: bold 17px/30px bebas_neue;
    letter-spacing: 1px;
    padding-top: 15px
}

#dg_footer_wrapper_inner .dg_footer_row_0 a,#dg_footer_wrapper_inner .dg_footer_row_0 a:hover {
    color: #33511c
}

#dg_footer_wrapper_inner .dg_footer_row_1 a,#dg_footer_wrapper_inner .dg_footer_row_1 a:hover {
    color: #33511c
}

#dg_footer_wrapper_inner .dg_footer_row_2 a,#dg_footer_wrapper_inner .dg_footer_row_2 a:hover {
    color: #33511c
}

#dg_footer_wrapper_inner .dg_footer_row_1,#dg_footer_wrapper_inner .dg_footer_row_2,#dg_footer_wrapper_inner .dg_footer_aside_left,#dg_footer_wrapper_inner .dg_footer_aside_left a,#dg_footer_wrapper_inner .dg_footer_aside_left a:hover #dg_footer_wrapper_inner .dg_footer_aside_right,#dg_footer_wrapper_inner .dg_footer_aside_right a,#dg_footer_wrapper_inner .dg_footer_aside_right a:hover {
    color: #33511c;
    font: nomral 13px/17px Georgia
}

#dg_footer_wrapper_inner .dg_footer_aside_right {
    text-align: center
}

#dg_footer_wrapper_inner .dg_footer_section_right_top {
    padding-top: 20px;
    line-height: 20px
}

#dg_footer_wrapper_inner .dg_footer_section_left_top {
    padding-top: 20px;
    line-height: 20px
}

#dg_footer_wrapper_inner .dg_footer_section_right_bottom {
    padding-top: 20px;
    line-height: 20px
}

#dg_footer_wrapper_inner .dg_footer_section_left_bottom {
    padding-top: 20px;
    line-height: 20px
}

#dg_footer_wrapper_inner ul li span {
    color: #33511c
}


#dg_footer_wrapper {
    background: #fff
}

#dg_footer_wrapper_inner {
    color: #000;
    font: normal normal 12px/25px arial;
    margin: 0 auto;
    overflow: hidden;
    text-align: center;
    position: relative;
    max-width: 100%
}

#dg_footer_wrapper_inner a {
    color: #00c;
    text-decoration: none
}

#dg_footer_wrapper_inner a:hover {
    text-decoration: underline
}

#dg_footer_wrapper_inner a img {
    border: 0;
    border: 1px solid #000
}

#dg_footer_wrapper_inner ul {
    list-style: none;
    padding: 0;
    margin: 0 auto;
    overflow: hidden;
    display: inline-block
}

#dg_footer_wrapper_inner ul li {
    display: inline-block;
    float: left
}

#dg_footer_wrapper_inner ul li a {
    display: inline-block;
    padding: 0 5px
}

#dg_footer_wrapper_inner ul li span {
    padding: 0 5px
}

#dg_footer_wrapper_inner ul li:after {
    content: "|";
    display: inline
}

#dg_footer_wrapper_inner ul li:first-child a {
    padding-left: 0
}

#dg_footer_wrapper_inner ul li:last-child a {
    padding-right: 0
}

#dg_footer_wrapper_inner ul li.dg_footer_item_last:after {
    content: ""
}

#dg_footer_wrapper_inner [class^=dg_footer_aside] {
    width: 200px;
    min-height: 100%;
    position: absolute;
    top: 0
}

#dg_footer_wrapper_inner .dg_footer_aside_left {
    left: 0
}

#dg_footer_wrapper_inner .dg_footer_aside_right {
    right: 0
}

#dg_footer_wrapper_inner .dg_footer_main.dg_footer_has_left_content {
    width: auto;
    margin-left: 200px
}

#dg_footer_wrapper_inner .dg_footer_main.dg_footer_has_right_content {
    width: auto;
    margin-right: 200px
}

#dg_footer_wrapper_inner [class^=dg_footer_row_] {
    clear: both;
    float: none;
    padding: 0 5px
}