body {
  font-family: myriad-pro,Arial,"Helvetica Neue",Helvetica,sans-serif;
  font-weight: 300;
  font-size: 1.125rem;
  line-height: 1.625rem;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  color: #ffffff;
}

/* extra css */
header{
  background-color: #000000;
}
.visually-hidden{
  visibility: hidden;
}
.bg-black{
  background-color: #000000 !important;
}
.bg-black .col-md-8 *{
  color: #ffffff;
}
.bg-black .col-md-8 a{
  color: #007bff;
}
.main-div {
  color: #212529;
  min-height: calc(100vh - 159px);
}
.nav-link{
  cursor: pointer;
}
/* ==== extra css and ==== */

/* For carousel */
.carousel-indicators{
  margin-bottom: 1rem;
}
.carousel-indicators button {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #fff;
  background-clip: padding-box;
  opacity: .5;
  transition: opacity 1s ease;
  border: 0;
}
.carousel-control-next-icon, .carousel-control-prev-icon {
  position: absolute;
  right: 50px;
}
.carousel-control-prev-icon{
  right: auto;
  left: 50px;
}
.carousel-item{
  max-height: 550px;
}
/* For carousel end */
.body--home-open {
  background-color: #000 !important;
  color: #fff !important;
}
.header {
  background-color: #000;
}
.navbar {
  font-family: myriad-pro, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 1rem;
  padding: 0.5rem 0;
}
.navbar .nav-link {
  text-transform: uppercase;
}
.navbar-brand {
  max-width: 300px;
}
nav.bg-dark {
  background-color: #000 !important;
}
.dropdown-toggle.nav-link a {
  text-decoration: none;
  color: inherit;
}
#age_logo_img {
  margin: 15px 0;
}
#age_content .age_logo {
  margin: auto;
  max-width: 320px;
}
@media (min-width: 767px) {
  .navbar-nav div:hover > .dropdown-menu {
    display: block;
  }
}
.element-invisible {
  display: none;
}
.breadcrumbs ol {
  list-style: none;
  display: flex;
  align-items: center;
}
.breadcrumb-separator {
  color: #333;
  margin: auto 6px;
  user-select: none;
}
.breadcrumb-collapser {
  display: flex;
  align-items: center;
}
.breadcrumb-collapser svg {
  cursor: pointer;
  transition: all 0.2s ease-out;
  color: #000;
  width: 25px;
  height: 25px;
}
.breadcrumb-collapser svg:hover,
.breadcrumb-collapser svg:focus {
  color: #999;
  transform: scale(1.15);
}
.breadcrumb-collapser svg:active {
  color: #333;
}
img {
  width: 100%;
}
.content {
  padding-top: 2em;
  font-family: myriad-pro, sans-serif;
}
.carousel {
  width: 100%;
  max-height: 550px;
  overflow: hidden;
}
.carousel .item {
  overflow: hidden;
}
.carousel .item img {
  height: 100%;
  max-width: none;
  left: 50%;
  position: relative;
  transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
}
.home_slider_wrapper {
  margin-bottom: 1em;
}
.teaser-box {
  padding: 30px 30px 27px;
  border: 15px solid #eeeeee;
  margin-bottom: 40px !important;
  transition: all 0.4s ease-in-out;
  -webkit-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  -ms-transition: all 0.4s ease-in-out;
  color: #767676;
  line-height: 24px;
  min-height: 336px;
}
.teaser-box {
  color: #000 !important;
  line-height: 24px;
  background: #fff !important;
  padding: 30px 30px 27px;
  border: 10px solid #000;
  margin-bottom: 0px !important;
  transition: all 0.4s ease-in-out;
  -webkit-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  -ms-transition: all 0.4s ease-in-out;
}
.teaser-box h4 {
  font: 25px/26px "Bodoni", Georgia, Helvetica, sans-serif;
  color: #252525;
}
.teaser-box h4,
.teaser-box p,
.teaser-box strong {
  color: #000;
}
.teaser-box a {
  color: #000;
}
.teaser-box img {
  height: auto;
  width: auto;
  max-width: 100%;
  margin: 0 auto;
  left: 50%;
  float: left;
  padding-right: 15px;
  filter: invert(100%);
}
hr,
.hr.divider {
  height: 1px;
  display: inline-block;
  background: 0;
  border-top: 0;
  border-bottom: 1px solid #dddddd;
  border-left: 0;
  border-right: 0;
  margin-top: 30px;
  margin-bottom: 30px;
  width: 100%;
}
.horizontal-break-alt {
  display: block;
  margin: 15px auto;
  width: 100%;
  border: none;
  position: relative;
}
hr.active,
.btn.filled.active,
.header-type-9 .top-bar,
.shopping-container .btn.border-grey:hover,
.bottom-btn .btn.btn-black:hover,
#searchModal .large-h:after,
#searchModal .btn-black,
.details-tools .btn-black:hover,
.product-information .cart button[type="submit"]:hover,
.all-fontAwesome .fa-hover a:hover,
.all-fontAwesome .fa-hover a:hover span,
.header-type-12 .shopping-container,
.portfolio-filters li .btn.active,
.progress-bar > div,
.wp-picture .zoom > i,
.swiper-slide .zoom > i,
.portfolio-image .zoom > i,
.thumbnails-x .zoom > i,
.teaser_grid_container .post-thumb .zoom > i,
.teaser-box h3:after,
.mc4wp-form input[type="submit"],
.ui-slider .ui-slider-handle,
.et-tooltip:hover,
.btn-active,
.rev_slider_wrapper .type-label-2,
.menu-social-icons.larger li a:hover,
.menu-social-icons.larger li a:focus,
.ui-slider .ui-slider-handle:hover,
.category-1 .widget_product_categories .widget-title,
.category-1 .widget_product_categories .widgettitle,
.category-1 .widget_nav_menu .widget-title,
.menu-social-icons.larger.white li a:hover,
.type-label-2,
.btn.filled:hover,
.btn.filled:focus,
.widget_shopping_cart .bottom-btn a:hover,
.horizontal-break-alt:after,
.price_slider_wrapper .price_slider_amount button:hover,
.btn.btn-black:hover,
.etheme_widget_search .button:hover,
input[type="submit"]:hover,
.project-navigation .prev-project a:hover,
.project-navigation .next-project a:hover,
.button:hover,
.mfp-close:hover,
.mfp-close:focus,
.tabs.accordion .tab-title:before,
#searchModal .btn-black:hover,
.toggle-block.bordered .toggle-element > a:before,
.place-order .button:hover,
.cart-bag .ico-sum,
.cart-bag .ico-sum:after {
  background-color: #bc2027;
}
.news .posted {
  display: block;
  font-size: 0.875rem;
  line-height: 0.875rem;
  opacity: 0.6;
}
.news h3,
.news h4,
.news .end {
  text-align: center;
}
#footer {
  margin-top: 2em;
}

@media (max-width: 600px) {
  .carousel-control-next-icon {
    right: 15px;
  }
  .carousel-control-prev-icon{
    left: 15px;
  }
}